import { ReactNode, useState, useContext } from 'react';
import {
  Box,
  CardMedia,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { CreditCardOff, Fort } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import EuroIcon from '@mui/icons-material/Euro';

import { EVENTS_URL, HTTP_METHOD } from 'const';
import { UserContext } from 'contexts';
import {
  isPastMidnight,
  isTwoWeeksBefore,
  parseDate,
  useFetch,
  useFetchFromBackend,
  useSnackbar,
  validateIban,
} from 'func';
import { Event } from 'types';

import { CardItem, OwlpostNote } from 'components/thestral';
import { Button, Dialog, TextField } from 'components/ui';
import { useFormik } from 'formik';

type Props = {
  item: Event;
};

export function EventCardItem(props: Readonly<Props>): ReactNode {
  const event = props.item;
  const EVENT_URL = `${EVENTS_URL}/${event?.id}`;

  const { user } = useContext(UserContext);
  const fetchFromBackend = useFetchFromBackend();
  const { data: status, triggerRefetch } = useFetch<string>(
    `${EVENT_URL}/status/${user?.id}`
  );
  const { showSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [payModalOpen, setPayModalOpen] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');
  const [errorPermission, setErrorPermission] = useState(false);

  function colorStatus() {
    switch (status) {
      case 'REGISTERED':
        return '#eab345';
      case 'PAID':
        return '#006207';
      case 'UNREGISTERED':
        return '#5f787b';
      case 'CANCELLED':
        return '#ff5252';
      case 'SEPA':
        return '#528cff';
      default:
        return '#5f787b';
    }
  }

  const price = isPastMidnight(event.priceChangeDate)
    ? isPastMidnight(event.priceChangeFinal)
      ? `${t('Components.Events.Pay.End')}`
      : event.price2
    : event.price1;

  const formik = useFormik({
    initialValues: {
      iban: user?.profile?.iban ?? '',
      permission: false,
      id: user?.id,
      accountHolder: `${user?.firstname} ${user?.lastname}`,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handlePaymentPerIban,
  });

  function validateName(name: string) {
    const trimmedName = name.trim();

    const nameParts = trimmedName
      .split(' ')
      .filter((part) => part.trim() !== '');

    return nameParts.length >= 2;
  }

  function handlePaymentPerIban(value) {
    setErrorPermission(false);
    setErrorMsg('');
    const isValidIban =
      value?.iban?.length > 0 ? validateIban(value.iban) : false;

    const isValidName = validateName(value.accountHolder);

    if (!isValidIban) {
      setErrorMsg(t('Components.Events.Pay.IbanError'));
    }

    if (!value.permission) {
      setErrorPermission(true);
      return;
    }

    if (value.permission && isValidIban && isValidName) {
      const body = JSON.stringify(value);
      setLoading(true);
      fetchFromBackend(`${EVENT_URL}/sepa`, {
        method: HTTP_METHOD.PATCH,
        body: body,
      })
        .then((response) => {
          const severity = response.ok ? 'success' : 'error';
          const result = response.ok
            ? t('Generic.Successful')
            : `${t('Generic.Failed')} (${response.status})`;

          if (response.ok) {
            triggerRefetch();
            setLoading(false);
            setPayModalOpen(false);
          }

          showSnackbar(
            `${t('Components.Events.Pay.IbanRegistration')} ${result}`,
            severity
          );
        })
        .finally(() => setLoading(false));
    }
  }

  function handlePaymentPerPaypal() {
    window.open('https://www.paypal.me/ThestralLARP', '_blank').focus();
    setPayModalOpen(false);
  }

  function cancelSEPA() {
    const body = JSON.stringify({});
    setLoading(true);

    fetchFromBackend(`${EVENT_URL}/sepa`, {
      method: HTTP_METHOD.PATCH,
      body: body,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        if (response.ok) {
          triggerRefetch();
          formik.values.permission = false;
        }

        showSnackbar(
          `${t('Components.Events.Pay.CancelSEPA')} ${result}`,
          severity
        );
      })
      .finally(() => setLoading(false));
  }

  const changeRegistration = (update) => {
    setLoading(true);
    fetchFromBackend(`${EVENT_URL}/${update}/${user?.id}`, {
      method: HTTP_METHOD.PUT,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        if (response.ok) {
          triggerRefetch();
        }

        const i18nKey = update === 'signup' ? 'SignUp' : 'Cancel';
        showSnackbar(
          `${t('Components.Events.' + i18nKey + '.Post')} ${result}`,
          severity
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {status !== 'PAID' &&
        status !== 'CANCELLED' &&
        status !== 'SEPA' &&
        status !== 'UNREGISTERED' &&
        !isPastMidnight(event.priceChangeFinal) && (
          <Button
            color='success'
            onClick={() => setPayModalOpen(true)}
            text={t('Components.Events.Pay.Post')}
            startIcon={<EuroIcon />}
            sx={{
              position: 'absolute',
              right: '0px',
              top: '0px',
              zIndex: '20',
            }}
          />
        )}
      {status === 'SEPA' && !isTwoWeeksBefore(event?.mandatePayDate) && (
        <Button
          color='error'
          onClick={() => cancelSEPA()}
          text={t('Components.Events.Pay.CancelSEPA')}
          startIcon={<CreditCardOff />}
          sx={{
            position: 'absolute',
            right: '0px',
            top: '0px',
            zIndex: '20',
          }}
        />
      )}
      {payModalOpen && (
        <Dialog
          open={payModalOpen}
          onClose={() => {
            setPayModalOpen(false), setErrorMsg('');
          }}
          title={`${event?.name} ${t('Components.Events.Pay.Post')}`}
          caption={`${parseDate(event?.start)} - ${parseDate(event?.end)}`}
          content={
            <Grid
              container
              sx={{
                alignItems: 'center',
                marginTop: '20px',
              }}
              gap={2}
            >
              <Grid item xs={12} md={3}>
                <Typography>{t('Components.Events.Price')}:</Typography>
                <Typography variant='caption'>{`${price} €`}</Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>
                  {isPastMidnight(event.priceChangeDate)
                    ? t('Components.Events.PriceChangeFinal')
                    : t('Components.Events.PriceChangeDate')}
                  :
                </Typography>
                <Typography variant='caption'>
                  {isPastMidnight(event.priceChangeDate)
                    ? `${parseDate(event?.priceChangeFinal)}`
                    : `${parseDate(event?.priceChangeDate)}`}
                </Typography>
              </Grid>
              {!isPastMidnight(event.priceChangeDate) && (
                <>
                  <Grid item xs={12} md={3}>
                    <Typography>
                      {t('Components.Events.Pay.MandatePayDate')}:
                    </Typography>
                    <Typography variant='caption'>{`${parseDate(
                      event?.mandatePayDate
                    )}`}</Typography>
                  </Grid>
                  <form>
                    <Grid
                      container
                      sx={{
                        alignItems: 'center',
                        marginTop: '20px',
                        justifyContent: 'center',
                      }}
                      gap={2}
                    >
                      <Grid item xs={12}>
                        <TextField
                          id='mandate'
                          label={t('Components.Events.Pay.MandateRef')}
                          value={event.mandateRef}
                          sx={{
                            width: '100%',
                            input: { color: '#fff' },
                            marginBottom: '10px',
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id='accountHolder'
                          label={t('Components.Events.Pay.Name')}
                          onChange={formik.handleChange}
                          value={formik.values.accountHolder}
                          sx={{
                            width: '100%',
                            input: { color: '#fff' },
                            marginBottom: '10px',
                          }}
                          required
                          error={!validateName(formik.values.accountHolder)}
                          inputProps={{ maxLength: 100 }}
                          helperText={
                            !validateName(formik.values.accountHolder)
                              ? t('Components.Events.Pay.NameError')
                              : ''
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id='iban'
                          label={t('Components.Events.Pay.Iban')}
                          onChange={formik.handleChange}
                          value={formik.values.iban}
                          sx={{
                            width: '100%',
                            input: { color: '#fff' },
                            marginBottom: '10px',
                          }}
                          required
                          error={errorMsg ? true : false}
                          inputProps={{ maxLength: 30 }}
                          helperText={errorMsg ? errorMsg : ''}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Checkbox
                          id='permission'
                          sx={{ outline: '#fff' }}
                          onChange={formik.handleChange}
                          value={formik.values.permission}
                        />
                        <Typography variant={'caption'}>
                          {t('Components.Events.Pay.Approval')}
                        </Typography>
                      </Grid>
                      {errorPermission && !formik.values.permission ? (
                        <Grid item xs={12} sx={{ marginTop: '-20px' }}>
                          <Typography
                            variant='caption'
                            sx={{ color: '#ff5252' }}
                          >
                            {t('Components.Events.Pay.PermissionError')}
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <Button
                          color='secondary'
                          onClick={formik.submitForm}
                          text={t('Components.Events.Pay.IbanPayment')}
                          sx={{ marginBottom: '10px', width: 'auto' }}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </>
              )}

              <Grid item xs={12}>
                <Button
                  color='primary'
                  onClick={handlePaymentPerPaypal}
                  text={t('Components.Events.Pay.PaypalPayment')}
                  sx={{ marginBottom: '10px', width: 'auto' }}
                />
              </Grid>
            </Grid>
          }
        ></Dialog>
      )}
      <CardItem
        dialogTitle={event?.name}
        dialogActions={
          <>
            {status === 'UNREGISTERED' && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <Button
                  color='success'
                  onClick={() => changeRegistration('signup')}
                  text={t('Components.Events.SignUp.Title')}
                />
                <Button
                  color='error'
                  onClick={() => changeRegistration('cancel')}
                  text={t('Components.Events.Cancel.Title')}
                />
              </Box>
            )}
            {status == 'REGISTERED' && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <Button
                  color='success'
                  onClick={() => setPayModalOpen(true)}
                  text={t('Components.Events.Pay.Title')}
                />
                <Button
                  color='error'
                  onClick={() => changeRegistration('cancel')}
                  text={t('Components.Events.Cancel.Title')}
                />
              </Box>
            )}
            {status == 'CANCELLED' && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <Button
                  color='success'
                  onClick={() => changeRegistration('signup')}
                  text={t('Components.Events.SignUp.Title')}
                />
              </Box>
            )}
            {status == 'PAID' && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <Button
                  color='error'
                  onClick={() => changeRegistration('cancel')}
                  text={t('Components.Events.Cancel.Title')}
                />
              </Box>
            )}
            {status === 'SEPA' && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                <Button
                  color='primary'
                  onClick={() => cancelSEPA()}
                  text={t('Components.Events.Pay.CancelSEPA')}
                />
                <Button
                  color='error'
                  onClick={() => changeRegistration('cancel')}
                  text={t('Components.Events.Cancel.Title')}
                />
              </Box>
            )}
          </>
        }
        dialogContent={
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h6'>
              <Trans i18nKey='Components.Events.Status' />
            </Typography>
            <Typography
              variant='body1'
              sx={{
                color: colorStatus(),
                fontSize: '1.2rem',
                marginBottom: '10px',
              }}
            >
              {t('Enum.ParticipationStatus.' + status)}
            </Typography>
            {status === 'SEPA' && (
              <>
                <Typography variant='h6'>
                  <Trans i18nKey='Components.Events.Pay.Iban' />
                </Typography>
                <Typography
                  variant='caption'
                  sx={{
                    marginBottom: '20px',
                    fontSize: '1.2rem',
                  }}
                >
                  {user?.profile?.iban}
                </Typography>
                <Typography variant='h6'>
                  <Trans i18nKey='Components.Events.Pay.MandatePayDate' />
                </Typography>
                <Typography
                  variant='caption'
                  sx={{
                    marginBottom: '20px',
                    fontSize: '1.2rem',
                  }}
                >
                  {parseDate(event?.mandatePayDate)}
                </Typography>
              </>
            )}
            {status === 'PAID' && <OwlpostNote event={event} />}
          </Box>
        }
        cardMedia={
          <CardMedia
            sx={{
              height: '180px',
              margin: 'auto',
              width: '65%',
              paddingTop: '10px',
              cursor: 'pointer',
            }}
          >
            <Fort
              sx={{
                width: '100%',
                height: '100%',
              }}
            />
          </CardMedia>
        }
        cardContent={
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <Typography
                  variant='h5'
                  gutterBottom
                  sx={{ textAlign: 'center', marginBottom: '0' }}
                  color='black'
                >
                  {event?.name}
                </Typography>
                <Typography
                  variant='h6'
                  sx={{ color: '#9e9e9e', textAlign: 'center' }}
                >
                  {parseDate(event?.start)} - {parseDate(event?.end)}
                </Typography>
                <Typography
                  variant='h6'
                  sx={{ color: colorStatus(), textAlign: 'center' }}
                >
                  {t('Enum.ParticipationStatus.' + status)}
                </Typography>
              </Box>
            )}
          </>
        }
      />
    </Box>
  );
}
