import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Suspense, useMemo, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme, oidcConfig, TraceProvider } from 'config';
import { UserContext } from 'contexts';

import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import {
  Admin,
  CharacterApproval,
  DraperyApproval,
  Home,
  Layout,
  NonPlayerCharacters,
  PlayerCharacters,
  PlayerDrapery,
  PrivacyImprint,
  Profile,
  Register,
  Signup,
  SignupApproval,
  WaitingList,
} from './pages';
import { LandingPage } from './pages/Home/LandingPage';
import { Clubs } from './pages/Clubs';
import {
  CodeOfConduct,
  Rules,
  RulesDrapery,
  RulesIT,
  RulesWorld,
} from './pages/Rules';
import { User } from 'types';

export function App() {
  const [user, setUser] = useState({} as User);
  const userContextValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  useTranslation();

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Suspense fallback={<div>Loading...</div>}>
          <TraceProvider>
            <AuthProvider {...oidcConfig}>
              <ThemeProvider theme={theme}>
                <UserContext.Provider value={userContextValue}>
                  <CssBaseline />
                  <Layout />
                </UserContext.Provider>
              </ThemeProvider>
            </AuthProvider>
          </TraceProvider>
        </Suspense>
      ),
      errorElement: <Error />,
      children: [
        { index: true, element: <Home /> },
        { path: 'profile', element: <Profile /> },
        { path: 'drapery', element: <PlayerDrapery /> },
        { path: 'characters', element: <PlayerCharacters /> },
        { path: 'npcs', element: <NonPlayerCharacters /> },
        {
          path: 'rules',
          children: [
            { index: true, element: <Rules /> },
            { path: 'drapery', element: <RulesDrapery /> },
            { path: 'it', element: <RulesIT /> },
            { path: 'world-building', element: <RulesWorld /> },
            { path: 'code-of-conduct', element: <CodeOfConduct /> },
          ],
        },
        {
          path: 'approval',
          children: [
            { path: 'characters', element: <CharacterApproval /> },
            { path: 'drapery', element: <DraperyApproval /> },
            { path: 'signups', element: <SignupApproval /> },
          ],
        },
        { path: 'admin', element: <Admin /> },
        { path: 'clubs', element: <Clubs /> },
        { path: 'register', element: <Register /> },
        { path: 'signup', element: <Signup /> },
        { path: 'login', element: <LandingPage /> },
        { path: 'waiting-list', element: <WaitingList /> },
        {
          path: 'privacy',
          element: <PrivacyImprint name='privacy' />,
        },
        {
          path: 'imprint',
          element: <PrivacyImprint name='imprint' />,
        },
        { path: '*', element: <NotFound /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
