import {
  Check,
  Close,
  CreditScore,
  Diamond,
  Forest,
  MeetingRoom,
  PriceCheck,
  Verified,
} from '@mui/icons-material';

import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';

import {
  ParticipationChip,
  ProfileDialog,
  ThestralDataGrid,
} from 'components/thestral';

import {
  HTTP_METHOD,
  EMPTY,
  HOUSE_FILTER,
  PLOT_FILTER,
  EVENTS_URL,
  PAID_FILTER,
} from 'const';
import { Participant, User } from 'types';
import { useFetchFromBackend, useSnackbar } from 'func';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { GridRenderCellParams, useGridApiRef } from '@mui/x-data-grid';

type Props = {
  participants?: Participant[];
  unregistered?: User[];
  eventId: number;
  triggerRefetch: () => void;
};

export function Participations(props: Readonly<Props>) {
  const fetchFromBackend = useFetchFromBackend();
  const { showSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<User>();
  const [open, setOpen] = useState<boolean>(false);

  const [activeChip, setActiveChip] = useState<string>();

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [colVisModel, setColVisModel] = useState({});

  useEffect(() => {
    if (props.participants || props.unregistered) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setColVisModel({
      'account.email': !isSm,
      'account.nickname': !isSm,
      'character.plot': !isSm,
      'character.house': !isSm,
    });
  }, [isSm]);

  const openProfile = (elem) => {
    setCurrentUser(elem.row.account);
    setOpen(true);
  };

  const openProfileUser = (elem) => {
    setCurrentUser(elem.row);
    setOpen(true);
  };

  const onStatusChange = (elem, type, isUnregistered) => {
    const id = isUnregistered ? elem.id : elem.account.id;

    setLoading(true);
    fetchFromBackend(`${EVENTS_URL}/${props.eventId}/${type}/${id}`, {
      method: HTTP_METHOD.PUT,
    })
      .then((response) => {
        const severity = response.ok ? 'success' : 'error';
        const result = response.ok
          ? t('Generic.Successful')
          : `${t('Generic.Failed')} (${response.status})`;

        const i18nKey = type == 'approve' ? 'Approval' : 'Cancel';
        showSnackbar(
          `${t('Components.Admin.Events.' + i18nKey)} ${result}`,
          severity
        );
      })
      .catch((error) => console.error(error))
      .finally(() => {
        props.triggerRefetch();
        setLoading(false);
      });
  };

  const apiRef = useGridApiRef();
  const [houseFilterCount, setHouseFilterCount] = useState([]);
  const [plotFilterCount, setPlotFilterCount] = useState([]);
  const [paidFilterCount, setPaidFilterCount] = useState([]);

  const getFilteredRowsCount = useCallback(
    (filterModel) => {
      if (!filterModel.items || filterModel.items.length === 0) {
        return props.participants?.length || 0;
      }

      const filteredRows = props.participants.filter((row) => {
        return filterModel.items.every((filterItem) => {
          const { field, operator, value } = filterItem;

          const cellValue = field
            .split('.')
            .reduce((obj, key) => obj?.[key], row);

          switch (operator) {
            case 'equals':
              return cellValue === value;
            default:
              return true;
          }
        });
      });

      return filteredRows.length;
    },
    [props.participants]
  );

  useEffect(() => {
    if (!props.participants) {
      return;
    }

    const houseCounts = HOUSE_FILTER.map(({ filterModel }) =>
      getFilteredRowsCount(filterModel)
    );
    const plotCounts = PLOT_FILTER.map(({ filterModel }) =>
      getFilteredRowsCount(filterModel)
    );
    const paidCounts = PAID_FILTER.map(({ filterModel }) =>
      getFilteredRowsCount(filterModel)
    );

    setHouseFilterCount(houseCounts);
    setPlotFilterCount(plotCounts);
    setPaidFilterCount(paidCounts);
  }, [apiRef, props.participants, getFilteredRowsCount]);

  const icon = (id: string): ReactNode => {
    switch (id) {
      case 'forest':
        return <Forest />;
      case 'artifacts':
        return <Diamond />;
      case 'rooms':
        return <MeetingRoom />;
      case 'paid':
        return <PriceCheck />;
      case 'registered':
        return <Check />;
      case 'sepa':
        return <CreditScore />;
    }

    return <></>;
  };

  const clickChip = (elem) => {
    if (elem.id === activeChip) {
      setActiveChip('');
    } else {
      setActiveChip(elem.id);
    }
  };

  return (
    <Grid container spacing={2}>
      {open && (
        <ProfileDialog
          open={open}
          onClose={() => setOpen(false)}
          id={currentUser?.id}
        />
      )}

      {!props.unregistered && (
        <>
          <Grid
            xs={12}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid grey',
              padding: '10px',
            }}
          >
            {HOUSE_FILTER.map((data, index) => {
              const count = houseFilterCount[index];
              return (
                <ParticipationChip
                  key={data.label}
                  data={data}
                  isActive={activeChip == data.id}
                  count={count}
                  apiRef={apiRef}
                  onClick={() => clickChip(data)}
                />
              );
            })}
          </Grid>

          <Grid
            xs={12}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid grey',
              padding: '10px',
            }}
          >
            {PLOT_FILTER.map((data, index) => {
              const count = plotFilterCount[index];
              return (
                <ParticipationChip
                  key={data.id}
                  data={data}
                  icon={icon(data.id)}
                  isActive={activeChip == data.id}
                  count={count}
                  apiRef={apiRef}
                  onClick={() => clickChip(data)}
                />
              );
            })}
          </Grid>

          <Grid
            xs={12}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '5px',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid grey',
              padding: '10px',
            }}
          >
            {PAID_FILTER.map((data, index) => {
              const count = paidFilterCount[index];
              return (
                <ParticipationChip
                  key={data.id}
                  data={data}
                  icon={icon(data.id)}
                  isActive={activeChip == data.id}
                  count={count}
                  apiRef={apiRef}
                  onClick={() => clickChip(data)}
                />
              );
            })}
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        {props.unregistered && (
          <ThestralDataGrid
            rowHeight={75}
            rows={props.unregistered || EMPTY}
            columnVisibilityModel={colVisModel}
            sx={{
              wordBreak: 'break-all',
              '@media (max-width:600px)': {
                height: '100 !important',
              },
            }}
            columns={[
              {
                field: 'name',
                headerName: t('Generic.Name'),
                sortable: true,
                flex: 1,
                filterable: true,
                valueGetter: (_, row) => `${row.lastname}, ${row.firstname}`,
              },
              {
                field: 'profile.nickname',
                headerName: t('Generic.Nickname'),
                sortable: true,
                flex: 1,
                valueGetter: (_, row) => {
                  return row?.profile?.nickname;
                },
              },
              {
                field: 'email',
                headerName: t('Generic.Email'),
                sortable: true,
                flex: 1,
              },

              {
                field: 'actions',
                headerName: t('Generic.Actions'),
                align: 'right',
                headerAlign: 'right',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      height: '100%',
                    }}
                  >
                    {loading && <CircularProgress />}
                    {!loading && (
                      <IconButton color='primary' sx={{ padding: '4px' }}>
                        <Tooltip
                          title={`${t('Enum.ParticipationStatus.PAID')}?`}
                        >
                          <Verified
                            onClick={(e) => {
                              e.stopPropagation();
                              onStatusChange(params?.row, 'approve', true);
                            }}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                    {!loading && (
                      <IconButton color='primary' sx={{ padding: '4px' }}>
                        <Tooltip
                          title={t('Components.Admin.Events.Cancellation')}
                        >
                          <Close
                            onClick={(e) => {
                              e.stopPropagation();
                              onStatusChange(params?.row, 'cancel', true);
                            }}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                  </Box>
                ),
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
              sorting: {
                sortModel: [{ field: 'lastname', sort: 'asc' }],
              },
            }}
            loading={loading}
            onRowClick={openProfileUser}
          />
        )}
        {props.participants && (
          <ThestralDataGrid
            apiRef={apiRef}
            sx={{
              minWidth: '200px',
            }}
            rowHeight={isSm ? 60 : 48}
            rows={props.participants || EMPTY}
            rowId={(row) => {
              return row?.account?.id;
            }}
            onRowClick={openProfile}
            columnVisibilityModel={colVisModel}
            columns={[
              {
                field: 'account.nickname',
                headerName: t('Generic.Nickname'),
                flex: 1,
                valueGetter: (_, row) => row.account?.nickname || '',
              },
              {
                field: 'name',
                headerName: t('Generic.Name'),
                flex: 2,

                valueGetter: (_, row) =>
                  `${row.account.lastname}, ${row.account.firstname}`,
              },
              {
                field: 'account.email',
                headerName: t('Generic.Email'),
                flex: 1,
                valueGetter: (_, row) => row.account?.email || '',
              },
              {
                field: 'character.house',
                headerName: t('Components.Characters.House'),
                flex: 1,
                valueGetter: (_, row) => {
                  const house = t(`Enum.House.${row.character.house}`);
                  return !row.character.house ? '' : isSm ? house[0] : house;
                },
              },
              {
                field: 'character.plot',
                headerName: t('Components.PlayerCharacters.Plot'),
                flex: 1,
                valueGetter: (_, row) => row.character?.plot || '',
                renderCell: (params: GridRenderCellParams) => (
                  <IconButton color='primary'>
                    {params?.row.character.plot === 'ARTIFACTS' && (
                      <Tooltip title={t('Enum.Plot.ARTIFACTS')}>
                        <Diamond />
                      </Tooltip>
                    )}
                    {params?.row.character.plot === 'FOREST' && (
                      <Tooltip title={t('Enum.Plot.FOREST')}>
                        <Forest />
                      </Tooltip>
                    )}
                    {params?.row.character.plot === 'ROOMS' && (
                      <Tooltip title={t('Enum.Plot.ROOMS')}>
                        <MeetingRoom />
                      </Tooltip>
                    )}
                  </IconButton>
                ),
              },
              {
                field: 'account.status',
                headerName: t('Components.Events.StatusShort'),
                flex: 0.7,
                valueGetter: (_, row) => row.account?.status || '',
                renderCell: (params: GridRenderCellParams) => (
                  <IconButton color='primary'>
                    {params?.row.account.status === 'REGISTERED' && (
                      <Tooltip title={t('Enum.ParticipationStatus.REGISTERED')}>
                        <Check />
                      </Tooltip>
                    )}
                    {params?.row.account.status === 'PAID' && (
                      <Tooltip title={t('Enum.ParticipationStatus.PAID')}>
                        <PriceCheck />
                      </Tooltip>
                    )}
                    {params?.row.account.status === 'SEPA' && (
                      <Tooltip title={t('Enum.ParticipationStatus.SEPA')}>
                        <CreditScore />
                      </Tooltip>
                    )}
                    {params?.row.account.status === 'CANCELLED' && (
                      <Tooltip title={t('Enum.ParticipationStatus.CANCELLED')}>
                        <Close />
                      </Tooltip>
                    )}
                  </IconButton>
                ),
              },
              {
                field: 'actions',
                headerName: t('Generic.Actions'),
                flex: 0.7,
                headerAlign: 'right',
                align: 'right',
                valueGetter: (_, row) => row.account?.status || '',
                renderCell: (params: GridRenderCellParams) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    {loading && <CircularProgress />}
                    {!loading && params?.row.account.status !== 'PAID' && (
                      <IconButton color='primary' sx={{ padding: '4px' }}>
                        <Tooltip
                          title={`${t('Enum.ParticipationStatus.PAID')}?`}
                        >
                          <Verified
                            onClick={(e) => {
                              e.stopPropagation();
                              onStatusChange(params?.row, 'approve', false);
                            }}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                    {!loading && params?.row.account.status !== 'CANCELLED' && (
                      <IconButton color='primary' sx={{ padding: '4px' }}>
                        <Tooltip
                          title={t('Components.Admin.Events.Cancellation')}
                        >
                          <Close
                            onClick={(e) => {
                              e.stopPropagation();
                              onStatusChange(params?.row, 'cancel', false);
                            }}
                          />
                        </Tooltip>
                      </IconButton>
                    )}
                  </Box>
                ),
              },
            ]}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
              sorting: {
                sortModel: [{ field: 'lastname', sort: 'asc' }],
              },
            }}
            loading={loading}
          />
        )}
      </Grid>
    </Grid>
  );
}
