import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, InputBaseComponentProps, TextField as MuiTextField, SxProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type Props = {
    defaultValue?: string;
    error?: boolean;
    helperText?: string;
    icon?: ReactNode;
    id?: string;
    name?: string;
    label?: string;
    multiline?: boolean;
    onBlur?: (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onChange?: (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown?: (evt) => void;
    readonly?: boolean;
    rows?: number;
    sx?: SxProps;
    value?: string;
    variant?: 'standard' | 'filled';
    type?: 'password' | 'number';
    endAdornment?: ReactNode;
    disabled?: boolean;
    required?: boolean;
    inputProps?: InputBaseComponentProps;
};

export function TextField(props: Readonly<Props>): ReactNode {
    const [shrink, setShrink] = useState<boolean>();
    const [showPassword, setShowPassword] = useState<boolean>();

    useEffect(() => {
        if (props.defaultValue) {
            setShrink(true);
        }
    }, [props.defaultValue]);

    const onBlur = (event) => {
        if (props.onBlur) props.onBlur(event);
        if (event.target.value === '') {
            setShrink(false);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            {props.icon}
            <MuiTextField
                name={props.name}
                defaultValue={props.defaultValue}
                error={props.error}
                fullWidth
                disabled={props.disabled}
                value={props.value}
                helperText={props.helperText}
                id={props.id}
                inputProps={props.inputProps}
                required={props.required}
                InputLabelProps={{
                    shrink,
                    sx: {
                        fontSize: '1.1rem',
                        fontFamily: 'Neuton',
                        color: 'rgba(255, 255, 255, 0.7)',
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: 'rgba(206, 205, 205, 0.7)',
                            WebkitTextFillColor: 'rgba(206, 205, 205, 0.7)',
                        },
                    },
                }}
                InputProps={{
                    readOnly: props.readonly,
                    sx: {
                        color: '#fff',
                        fontFamily: 'Neuton',
                        fontSize: '16px',
                        '& .MuiInputBase-input.Mui-disabled': {
                            color: 'rgba(206, 205, 205, 0.7)',
                            WebkitTextFillColor: 'rgba(206, 205, 205, 0.7)',
                        },
                    },
                    endAdornment:
                        props.type === 'password' ? (
                            <InputAdornment position='end'>
                                <IconButton color='primary' onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            props?.endAdornment
                        ),
                }}
                label={props.label}
                margin='dense'
                multiline={props.multiline}
                onBlur={onBlur}
                onChange={props.onChange}
                onFocus={() => setShrink(true)}
                onKeyDown={props.onKeyDown}
                rows={props.rows}
                sx={{
                    ...props.sx,
                    marginBottom: '0px',
                    '& .MuiInput-underline:before': {
                        borderBottomColor: '#fff',
                    },
                    '& .MuiInput-underline:after': {
                        color: '#fff',
                    },
                }}
                variant={props.variant ?? 'standard'}
                type={showPassword ? 'text' : props.type}
            />
        </Box>
    );
}
